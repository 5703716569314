import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import MenuContent from './menuContent';

const MenuComponent = () => (
  <StaticQuery
    query={graphql`
      query Menu {
        allMenuJson(sort: { fields: order }) {
          edges {
            node {
              title
              tag
              description
              section {
                title
                content {
                  text
                  price
                }
              }
            }
          }
        }
      }
    `}
    render={data =>
      data.allMenuJson.edges.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <section key={i} className={`menu menu-bg-${item.node.tag}`}>
          <Container className="pb-5 pt-4" style={{ width: '50%' }}>
            <Row>
              <Col className="content-header" md={12}>
                <h1>{item.node.title}</h1>
                <p>{item.node.description}</p>
                <br className="break" />
              </Col>
              {item.node.section.map(section => (
                <MenuContent title={section.title} list={section.content} />
              ))}
            </Row>
          </Container>
        </section>
      ))
    }
  />
);

export default MenuComponent;
