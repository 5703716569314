import React from 'react';
import { Col } from 'react-bootstrap';

const MenuContent = ({ title, list }) => (
  <>
    <Col md={12}>
      <br />
      <h2>{title}</h2>
    </Col>
    {list.map(i => (
      <>
        <Col className="content-menu-top" md={9}>
          <p className="content-item-text">{i.text}</p>
        </Col>
        <Col className="content-menu-bottom price-text" md={3}>
          <p>{i.price}€</p>
        </Col>
      </>
    ))}
  </>
);

export default MenuContent;
