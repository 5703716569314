import React from 'react';
import Layout from '../components/layout';
import MenuComponent from '../components/menuComponent';
import SEO from '../components/seo';

const Menu = () => (
  <Layout>
    <SEO title="Menu" description="Aktuálna ponuka čokolád, limonád ale aj alko nápojov." />
    <MenuComponent />
  </Layout>
);

export default Menu;
